<template>
  <div class="d-block d-md-flex">
    <div class="form__section py-10 pt-md-11 px-6 px-md-7">
      <v-form ref="form">
        <div class="d-inline-flex d-md-flex justify-space-between">
          <div class="pr-4">
            <p class="mb-0 sub__title">Total amount</p>
            <h2
              class="mb-md-0 amount"
              @click.prevent="toggleField"
              v-if="changeState"
            >
              {{ invoice.total_amount | currency }}
            </h2>
            <v-text-field
              @blur="toggleField"
              v-if="!changeState"
              v-model.number="initialAmount"
              :background-color="backgroundColor"
              :rules="rules.amount"
              min="0"
              solo
              :autofocus="true"
              flat
              dense
              hide-details="auto"
            >
            </v-text-field>
          </div>
          <v-chip
            v-if="$vuetify.breakpoint.mdAndUp"
            text-color="#2BD5AE"
            style="background-color: #eefcf9"
            class="chipStyle px-2"
            >{{ invoice.invoiceType }}</v-chip
          >
        </div>

        <div class="customer-details">
          <p class="mb-0 pt-2 pb-6 section__title">Customer Details</p>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0 mt-md-3 form__label">Select Group</p>
            </v-col>
            <v-col cols="12" sm="6" md="8">
              <v-select
                class="py-3"
                v-model="selectedTags"
                label="Select items"
                multiple
                chips
                small-chips
                x-small-chips
                deletable-chips
                :items="customersTags"
                item-value="tag_name"
                item-text="label"
                :menu-props="{ offsetY: true, searchable: true, lazy: true }"
                :disabled="review"
                :rules="rules.required"
                solo
                reverse
                flat
                dense
                hide-details
                :background-color="inputBackgroundColor"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    x-small
                    v-if="index < 2"
                    :key="item.tag_name"
                    text-color="#19283d"
                    style="background-color: #e3aa1c; border: #f7e8c2"
                    dark
                    class="my-1"
                  >
                    {{ item.label }}
                  </v-chip>
                  <span
                    class="text-caption"
                    v-if="index === 2 && selectedTags.length > 2"
                  >
                    and {{ selectedTags.length - 2 }} more</span
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>
          <hr class="hr-line" />
          <div class="text-right pt-1 pb-3">
            <span class="link__text" @click="OpenCustomerModal"
              >+ New Customer</span
            >
          </div>
          <hr class="hr-line" />
        </div>
        <div class="invoice-details pt-4 pt-md-8">
          <div class="">
            <v-row class="pt-2 pb-2">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-2 form__label">Invoice no.</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-text-field
                  v-model="invoice.invoicenumber"
                  hide-details="auto"
                  disabled
                  :background-color="inputBackgroundColor"
                  solo
                  flat
                  dense
                  reverse
                  placeholder="Automatically generated"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>

          <div>
            <v-row class="pt-4 pb-1">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">Date</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatedDate"
                      hide-details="auto"
                      :disabled="review"
                      :rules="rules.required"
                      :background-color="inputBackgroundColor"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                      reverse
                      placeholder="Enter Date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.date"
                    @input="menu = false"
                    no-title
                    scrollable
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>

          <div>
            <v-row class="pt-4">
              <v-col cols="10" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">VAT</p>
              </v-col>
              <v-col cols="2" sm="6" md="8" class="py-0 ma-0">
                <div class="pb-4 d-flex align-center justify-md-end">
                  <v-switch
                    v-if="$vuetify.breakpoint.mdAndUp"
                    true-value="1"
                    false-value="0"
                    hide-details="auto"
                    color="primary"
                    v-model="invoice.vat"
                    :disabled="review"
                  ></v-switch>
                  <v-checkbox
                    v-if="$vuetify.breakpoint.smAndDown"
                    true-value="1"
                    false-value="0"
                    v-model="invoice.vat"
                    :background-color="inputBackgroundColor"
                    color="primary"
                    hide-details="auto"
                    :disabled="review"
                  >
                  </v-checkbox>
                </div>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>
          <div>
            <v-row class="pt-4 pb-1">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">Due Date</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formatedDueDate"
                      hide-details="auto"
                      :disabled="review"
                      :background-color="inputBackgroundColor"
                      :rules="rules.required"
                      solo
                      flat
                      dense
                      v-bind="attrs"
                      v-on="on"
                      reverse
                      placeholder="Enter Date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.due_date"
                    @input="menu2 = false"
                    no-title
                    scrollable
                    :min="minDate"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>
          <div>
            <v-row class="pt-4 pb-1">
              <v-col cols="12" sm="6" md="4">
                <p class="mb-0 mt-md-3 form__label">Currency</p>
              </v-col>
              <v-col cols="12" sm="6" md="8" class="py-1 ma-0">
                <v-select
                  v-model="invoice.currency"
                  :items="currencies"
                  hide-details="auto"
                  :disabled="review"
                  :background-color="inputBackgroundColor"
                  :rules="rules.required"
                  solo
                  flat
                  dense
                  reverse
                  placeholder="Select Currency"
                >
                </v-select>
              </v-col>
            </v-row>
            <hr class="hr-line" />
          </div>
          <div class="pt-4">
            <p class="mb-0 pb-6 section__title">Invoice Items</p>
            <div v-for="(line, index) in invoice.lines" :key="`Input-${index}`">
              <v-row>
                <v-col cols="12" sm="6" md="7" class="pr-md-0 pb-1 pb-md-4">
                  <v-text-field
                    v-model="line.description"
                    hide-details="auto"
                    :disabled="review"
                    :background-color="inputBackgroundColor"
                    :rules="rules.required"
                    solo
                    flat
                    dense
                    placeholder="Item"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="5" class="pl-md-0 pt-1 pt-md-3">
                  <div class="d-flex d-md-flex align-center pl-1">
                    <v-btn
                      v-if="invoice.lines[index].quantity > 0"
                      x-small
                      color="primary"
                      :disabled="review"
                      icon
                      @click="decreaseQty(index)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <p class="quantity mb-0 px-2">{{ line.quantity }}</p>
                    <v-btn
                      x-small
                      color="primary"
                      :disabled="review"
                      icon
                      @click="increaseQty(index)"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>

                    <v-text-field
                      class="ml-1"
                      v-model.number="line.price"
                      hide-details="auto"
                      :disabled="review"
                      :background-color="inputBackgroundColor"
                      :rules="rules.price"
                      solo
                      flat
                      dense
                      placeholder="Price"
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
              <hr class="hr-line" />
            </div>
            <div class="d-flex justify-space-between pt-1 pb-3">
              <p
                class="remove__text text-capitalize"
                text
                @click="removeInput(invoice.lines)"
                :disabled="review"
                v-show="invoice.lines && invoice.lines.length > 1"
              >
                - last item
              </p>
              <p></p>

              <p
                class="link__text text-right text-capitalize"
                text
                @click="addInput"
                :disabled="review"
              >
                + New Item
              </p>
            </div>
            <hr class="hr-line" />
          </div>
        </div>
        <div></div>
        <div class="text-center pt-14" style="">
          <v-btn
            block
            class="py-6"
            elevation="0"
            color="white"
            :loading="isLoading"
            @click="saveInvoice"
          >
            <v-icon>mdi-check</v-icon>
            <span class="pl-8 pr-14 text-capitalize">{{ toggleBtnText }}</span>
          </v-btn>
        </div>
        <div class="text-center pt-6" style="padding-bottom: 20px">
          <v-btn
            block
            class="py-6"
            elevation="10"
            color="primary"
            :disabled="invoiceIsSent"
            @click="showSendInvoiceModal"
          >
            <simple-line-icons
              style="width: 12px; height: 12px"
              class="m-0 text--white pl-14"
              icon="cursor"
              medium
              no-svg
            />
            <span class="pl-12 pr-14 text-capitalize">Save & Send</span>
          </v-btn>
        </div>
      </v-form>
    </div>
    <div class="preview__section px-6 px-md-12">
      <div class="d-flex align-center pr-5 py-md-8" style="width: 100%">
        <v-breadcrumbs :items="breadcrumbs" class="px-md-2 breadcrumbs">
          <template v-slot:divider>
            <v-icon class="px-0">mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          @click="$router.go(-1)"
          text
          rounded
          large
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-icon left>mdi-chevron-left</v-icon> Back
        </v-btn>
      </div>
      <v-card v-if="newInvoiceData" class="px-6 py-6 px-md-12 py-md-12">
        <InvoiceTemplate
          :invoicenumber="newInvoiceData.invoiceNumber"
          :company_from="organization.orgname"
          :address_from="organization.office[0].address || ''"
          :company_to="
            newInvoiceData.customer.company.company_name || 'customer name'
          "
          :total="newInvoiceData.total"
          :subTotal="newInvoiceData.total_wht"
          :lines="newInvoiceData.lines"
          :city_from="organization.office[0].city || ''"
          :country_from="organization.office[0].country || ''"
          :zip_from="organization.office[0].postalcode || ''"
          :date="new Date() | date"
          :dueDate="newInvoiceData.due_date"
          :organizationLogo="organization.logo"
        />
      </v-card>
      <v-card
        v-else
        v-model="review"
        class="px-6 py-6 px-md-12 py-md-12 d-flex justify-center align-center"
        elevation="6"
        style="width: 100%; min-height: 700px"
      >
        <div class="d-flex justify-center align-center">
          <p class="invoice__prev">invoice preview</p>
        </div>
      </v-card>
    </div>
    <AddCustomer ref="AddCustomer" />
    <SendCustomerInvoice ref="SendInvoiceModal" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddCustomer from "../../includes/overlays/ContactsMenuModals/AddCustomer.vue";
import SendCustomerInvoice from "./components/sendCustomerInvoice.vue";
import InvoiceTemplate from "./components/invoiceTemplate.vue";
export default {
  name: "RequestPayment",
  data() {
    return {
      // searchQuery: "",
      menu: false,
      menu2: false,
      isLoading: false,
      changeState: false,
      organLogo: "",
      review: false,
      initialAmount: 0,
      canSendInvoice: false,
      invoiceIsSent: false,
      invoice: {
        total_amount: 0,
        source: "form",
        invoiceType: "Customer Invoice",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        due_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        lines: [
          {
            description: "",
            quantity: 1,
            price: 0,
          },
        ],
      },
      currencies: ["NGN"],
      breadcrumbs: [
        {
          text: "Inbox",
          to: "/inbox",
          disabled: true,
        },
        {
          text: "Invoice",
          to: "#",
          disabled: true,
        },
        {
          text: "New Invoice",
          disabled: false,
        },
      ],
      rules: {
        required: [(v) => !!v || "This Field is required"],
        price: [(v) => !!v || "Price is required"],
        amount: [
          (v) => !!v || "Amount is required",
          (v) => v < 0 || "Amount must be greater than 0",
        ],
      },
      selectedTags: [],
    };
  },
  computed: {
    //mapGetters to get the state of the store

    ...mapGetters({
      customersTags: "contacts/getCustomerTags",
      organizationId: "auth/organizationId",
      organization: "organizations/organObj",
      newInvoiceData: "invoices/getNewInvoiceData",
    }),

    inputBackgroundColor() {
      return this.review ? "transparent" : "#ffffff";
    },
    backgroundColor() {
      return this.changeState ? "transparent" : "#ffffff";
    },
    formatedDate() {
      const date = new Date(this.invoice.date);

      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return day + "/" + month + "/" + year;
    },
    formatedDueDate() {
      // const date = new Date(this.invoice.due_date);

      // let year = date.getFullYear();
      // let month = date.getMonth() + 1;
      // let day = date.getDate();

      // if (day < 10) {
      //   day = "0" + day;
      // }
      // if (month < 10) {
      //   month = "0" + month;
      // }
      // return day + "/" + month + "/" + year;

      return this.$moment(this.invoice.due_date).format("DD/MM/YYYY");
    },

    //get minimum date using moment
    minDate() {
      return this.$moment().format("YYYY-MM-DD");
    },

    toggleBtnText() {
      if (this.review) {
        return "Review";
      } else return "Save";
    },

    //calculate the subtotal of price and quantity
    subTotal() {
      let total = 0;
      if (this.invoice.lines[0].quantity === 1) {
        total += Math.abs(this.invoice.lines[0].price);
      } else {
        this.invoice.lines.forEach((line) => {
          total += line.quantity * Math.abs(line.price);
        });
      }

      return total;
    },

    //get the lenght of the invoice lines array
    lineItems() {
      let x = this.invoice.lines.length;
      return x.toString();
    },

    //get the create customer invoice payload
    createCustomerInvoicePayload() {
      return {
        tags: this.selectedTags,
        source: this.invoice.source,
        due_date: this.invoice.due_date,
        date: this.invoice.date,
        total: this.invoice.total_amount.toString(),
        subtotal: this.subTotal.toString(),
        vat: this.invoice.vat,
        organization: this.organizationId.toString(),
        line_items: this.lineItems,
        lines: this.invoice.lines,
      };
    },

    //get organization logo
  },
  methods: {
    //mapActions
    ...mapActions({ showToast: "ui/showToast" }),

    //fetch all customers
    async getAllCustomers() {
      try {
        await this.$store.dispatch("contacts/fetchAllCustomers");
      } catch (error) {
        if (process.env.NODE_ENV === "developement") {
          console.log(error);
        }
      }
    },

    toggleField() {
      this.changeState = !this.changeState;
    },
    OpenCustomerModal() {
      this.$refs.AddCustomer.show(true);
      console.log("hello");
    },

    addInput() {
      this.invoice.lines.push({
        description: "",
        quantity: 1,
        price: 0,
      });
    },
    removeInput(inputType) {
      let index = -1;
      inputType.splice(index, 1);
    },

    //increase quantity
    increaseQty(index) {
      //check if quantity has a string value and change it to a number
      // if (this.invoice.lines[index].quantity === "QTY") {
      //   this.invoice.lines[index].quantity = 0;
      // }

      this.invoice.lines[index].quantity++;
    },
    //decrease quantity
    decreaseQty(index) {
      if (this.invoice.lines[index].quantity > 1) {
        this.invoice.lines[index].quantity--;
      }
    },
    //save invoice for preview
    async saveInvoice() {
      if (this.$refs.form.validate()) {
        if (!this.review) {
          this.review = true;
          this.isLoading = true;

          try {
            await this.$store
              .dispatch(
                "invoices/createCustomerInvoice",
                this.createCustomerInvoicePayload
              )
              .then((res) => {
                if (res.status === "success") {
                  this.showToast({
                    sclass: "success",
                    show: true,
                    message: "customer invoice created successfully",
                    timeout: 3000,
                  });
                  this.canSendInvoice = true;
                }
              });

            console.log(JSON.stringify(this.newInvoiceData, null, 2));
          } catch (error) {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
            }

            this.showToast({
              sclass: "error",
              show: true,
              message: "Error creating customer invoice..",
              timeout: 3000,
            });
          } finally {
            this.isLoading = false;
            //clear the form to prevent duplicate data
            // this.$refs.form.reset();
          }
        } else {
          this.review = false;
        }
      }
    },

    //check if invoice is saved and open the send invoice modal
    async showSendInvoiceModal() {
      if (this.canSendInvoice && !this.invoiceIsSent) {
        this.$refs.SendInvoiceModal.show(true);
        this.invoiceIsSent = true;
      } else {
        await this.saveInvoice();
        if (this.canSendInvoice && !this.invoiceIsSent) {
          this.$refs.SendInvoiceModal.show(true);
          this.invoiceIsSent = true;
        }
      }
    },
  },

  components: { AddCustomer, SendCustomerInvoice, InvoiceTemplate },

  mounted() {
    this.getAllCustomers();
    if (this.newInvoiceData) {
      this.invoice = this.newInvoiceData;
    }
  },

  watch: {
    //watch the customers variable and update the customers array
    customersTags: {
      handler(val) {
        if (!val) {
          this.getAllCustomers();
        }

        // this.getAllCustomers();
      },
      deep: true,
      immediate: true,
    },

    initialAmount: {
      handler(val) {
        // convert val to positive number
        val = Math.abs(val);

        this.invoice.lines[0].price = val;
        console.log(val);
        this.getAllCustomers();
      },
      immediate: true,
      deep: true,
    },

    //watch subTotal to calculate the total amount
    subTotal: {
      handler(val) {
        this.invoice.total_amount = val;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.form__section {
  width: 100%;
  background: #f2f2f2;

  .sub__title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: rgba(25, 40, 61, 0.8);
  }

  .amount {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    color: #455160;
    cursor: pointer;
  }
}

.preview__section {
  width: 100%;
  height: 100%;
  //   min-height: 100vh;
  background: #f7f7f7;
}

.quantity {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 12px;
  color: #19283d;
  cursor: pointer;
}

@media screen and (min-width: 992px) {
  .form__section {
    width: 38%;
    min-height: 100vh;
    background: #f6f3ee;
    display: flex;
    flex-flow: column;

    .sub__title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: rgba(25, 40, 61, 0.8);
    }

    .amount {
      font-weight: 700;
      font-size: 22px;
      line-height: 18px;
    }
  }

  .preview__section {
    width: 62%;
    height: 100%;
    background: #f7f7f7;
  }

  .customer-details {
    padding-top: 64px;
  }
}

.section__title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(25, 40, 61, 0.8);
}

.form__label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  color: #19283d;
}

i.sli-font {
  font-size: 16px;
  display: inline-block;
}

.link__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #5b67ba;
  cursor: pointer;
}

.remove__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: red;
  cursor: pointer;
}

.link__text:hover {
  color: #515ca0;
  font-weight: 700;
}

.chipStyle {
  font-weight: 500;
}

.breadcrumbs {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #8f96a1;
}

.invoice__prev {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #7f919b;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.hr-line {
  border-top: 1px dashed rgba(40, 28, 75, 0.233);
}

.v-application .elevation-6 {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 3%), 0px 6px 10px 0px rgb(0 0 0 / 3%),
    0px 1px 18px 0px rgb(0 0 0 / 3%) !important;
}
</style>
